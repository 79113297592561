<template>
  <div>
    <div class="mb-5">
      <h1 class="mt-0 fs-b fs-2500 color-pr">
        Création de compte impots.gouv.fr
      </h1>
    </div>
    <div class="text-left">
      <a
        @click="goToRattachement()"
        class="fr-link p-0 color-pr fs-875 soulignement"
        title="Retour aux Rattachements"
        ><i class="ri-arrow-left-line mr-2"></i>Retour aux Rattachements</a
      >
    </div>

    <div class="d-flex justify-content-center">
      <div class="mb-2 w-75">
        <div class="text-center mt-2 creation-message">
          Pour finaliser la création de votre compte impots.gouv.fr, nous avons
          besoin d'informations <br />
          supplémentaires. Merci de bien vouloir compléter le formulaire
          ci-dessous.
        </div>
        <div class="champs-obligatoire mt-3">* Champs obligatoires</div>

        <div class="mt-4">
          <div class="row">
            <div class="col-sm-12">
              <label class="fr-label" for="adresse-input"
                ><span
                  :class="`span-label${
                    compte.adresseRequiredError === true
                      ? ' label-span-error'
                      : ''
                  }
              `"
                  >Adresse</span
                >
                *</label
              >
              <input
                :class="`fr-input${
                  compte.adresseRequiredError === true ? ' input-error' : ''
                }
              `"
                placeholder="N° voie - Type de voie - Libellé de voie"
                id="adresse-input"
                name="adresse-input"
                v-model="compte.adresse"
                maxlength="80"
                @keyup="checkCaractereNonImprimable()"
              />
              <p
                class="message-error d-flex align-items-center"
                v-if="compte.adresseRequiredError"
              >
                <i class="ri-error-warning-line" />
                Ce champ est obligatoire.
              </p>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-sm-6">
              <label class="fr-label" for="complement-1-input"
                >Complément d'adresse</label
              >
              <input
                class="fr-input"
                placeholder="Complément 1"
                id="search-complement-1"
                name="search-complement-1"
                v-model="compte.complement1"
                maxlength="80"
                @keyup="checkCaractereNonImprimable()"
              />
            </div>
            <div class="col-sm-6 complement2-mobile">
              <label class="fr-label" for="complement-2-input"
                >Complément d'adresse</label
              >
              <input
                class="fr-input"
                placeholder="Complément 2"
                id="complement-2-input"
                name="complement-2-input"
                v-model="compte.complement2"
                maxlength="80"
                @keyup="checkCaractereNonImprimable()"
              />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-sm-4">
              <label class="fr-label" for="code-postal-input">
                <span
                  :class="`span-label${
                    compte.codePostalRequiredError === true
                      ? ' label-span-error'
                      : ''
                  }
              `"
                >
                  Code postal</span
                >
                *</label
              >
              <input
                :class="`fr-input${
                  compte.codePostalRequiredError === true ? ' input-error' : ''
                }
              `"
                placeholder="00000"
                id="code-postal-input"
                name="code-postal-input"
                v-model="compte.codePostal"
                maxlength="20"
                @keyup="checkCaractereNonImprimable()"
              />
              <p
                class="message-error d-flex align-items-center"
                v-if="compte.codePostalRequiredError"
              >
                <i class="ri-error-warning-line" />
                Ce champ est obligatoire.
              </p>
            </div>
            <div class="col-sm-4 ville-mobile">
              <label class="fr-label" for="ville-input">
                <span
                  :class="`span-label${
                    compte.villeRequiredError === true
                      ? ' label-span-error'
                      : ''
                  }
              `"
                >
                  Ville</span
                >
                *</label
              >
              <input
                :class="`fr-input${
                  compte.villeRequiredError === true ? ' input-error' : ''
                }
              `"
                placeholder="Nom ville"
                id="ville-input"
                name="ville-input"
                v-model="compte.ville"
                maxlength="40"
                @keyup="checkCaractereNonImprimable()"
              />
              <p
                class="message-error d-flex align-items-center"
                v-if="compte.villeRequiredError"
              >
                <i class="ri-error-warning-line" />
                Ce champ est obligatoire.
              </p>
            </div>
            <div class="col-sm-4 pays-mobile">
              <label class="fr-label" for="pays-input"> Pays *</label>

              <InputPays
                :options="adressePayslist"
                @input-pays="getSelectedPays"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 mt-4">
              <label class="fr-label mb-2" for="captcha-input"
                ><span
                  :class="`span-label${
                    compte.captchaRequiredError === true ||
                    compte.captchaInvalidError == true
                      ? ' label-span-error'
                      : ''
                  }
              `"
                  >Captcha</span
                >
                *</label
              >
              <div class="d-flex flex-row align-items-center">
                <img
                  for="captcha-input"
                  :src="'data:image/png;base64,' + captcha.captchaImg"
                  width="275"
                  height="50"
                  class="mr-2 captcha-image"
                  alt="image d'un captcha"
                />
                <img
                  src="../assets/img/speaker.svg"
                  width="40"
                  height="40"
                  alt="icone haut parleur"
                  @click="playCaptchaAudio()"
                  class="captcha-btn"
                />
                <img
                  src="../assets/img/refresh-captcha.svg"
                  width="30"
                  height="30"
                  class="ml-2 captcha-btn"
                  alt="icone d'une fléche qui forme un cercle"
                  @click="getCaptchaImage()"
                />
              </div>
              <input
                :class="`fr-input mt-2${
                  compte.captchaRequiredError === true ||
                  compte.captchaInvalidError == true
                    ? ' input-error'
                    : ''
                }
              `"
                placeholder="Captcha"
                id="captcha-input"
                name="captcha-input"
                v-model="captchaReponse"
                maxlength="7"
              />

              <p
                class="message-error d-flex align-items-center"
                v-if="compte.captchaRequiredError"
              >
                <i class="ri-error-warning-line" />
                Ce champ est obligatoire.
              </p>
              <p
                class="message-error d-flex align-items-center"
                v-if="compte.captchaInvalidError"
              >
                <i class="ri-error-warning-line" />
                Le captcha est invalide.
              </p>
            </div>
          </div>

          <div class="text-center mb-5 mt-5">
            <button
              @click="goToRattachement()"
              class="pr-btn-annuler mr-4"
              title="Cliquer ici pour annuler"
            >
              <span class="span-btn">Annuler</span>
            </button>
            <button
              class="pr-btn-valider"
              @click="valider()"
              title="Cliquer ici pour valider"
            >
              <span class="span-btn">Valider</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkipLinkMixin from '../mixins/skip-link-mixin';

import { mapActions, mapGetters } from 'vuex';
import {
  cleanText,
  getCreationCompteTechniqueErreurMessage,
  getEtatCivil
} from '../utils/methodsUtils';
import { DGFIP, PAYS, FRANCE } from '../constantes';
import InputPays from '../components/InputPays.vue';

export default {
  components: {
    InputPays
  },
  mixins: [SkipLinkMixin],
  data() {
    return {
      compte: {
        adresse: '',
        complement1: '',
        complement2: '',
        codePostal: '',
        ville: '',
        pays: FRANCE,
        adresseRequiredError: false,
        codePostalRequiredError: false,
        villeRequiredError: false,
        captchaRequiredError: false,
        captchaInvalidError: false
      },
      adressePayslist: PAYS.filter(
        (p) =>
          p.indicatif === '33' || p.indicatif === '376' || p.indicatif === '377'
      ),
      captcha: {
        captchaId: '',
        captchaImg: ''
      },
      captchaReponse: '',
      captchaValidation: false
    };
  },

  mounted() {
    if (this.comptesRattachees?.dgfip === true) {
      this.$router.push({ name: 'Gestion des rattachements' });
    } else {
      this.getCaptchaImage();
    }
  },

  computed: {
    ...mapGetters(['email', 'idPCR', 'isAge', 'comptesRattachees'])
  },

  methods: {
    getSelectedPays(payload) {
      this.compte.pays = payload;
    },
    valider() {
      let html =
        "<p class='fs-875 text-center'>Souhaitez vous continuer la création de votre compte <b>impots.gouv.fr</b> ?</p>";
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', async (payload) => {
        if (payload === 'oui') {
          if ((await this.checkFields()) === true) {
            const demande = {
              mail: this.email,
              sub: this.idPCR,
              coproprietaire: DGFIP.codeCopro,
              siren: this.$store.state.compte.siren,
              etatCivil: this.prepateEtatCivil()
            };

            this.$comptesService
              .creationDemandeDGFIP(demande)
              .then((result) => {
                if (result.status != 201) {
                  let html = `<p class='fs-875'>L'utilisateur n'existe pas.</p>`;

                  this.setContent({ html, action: 'info' });
                  this.showPopup();
                } else {
                  this.$router.push({
                    name: 'Gestion des rattachements',
                    query: {
                      creationCT: DGFIP.codeCopro,
                      html: this.getHtmlMessage()
                    }
                  });
                }
              })
              .catch((err) => {
                const creationError = getCreationCompteTechniqueErreurMessage(
                  err?.response?.data?.code
                );
                if (!creationError || !creationError.message) {
                  const html = `<p class="fs-875">Votre demande n'a pas pu aboutir. Veuillez la renouveler ultérieurement.</p>`;
                  this.setContent({ html, action: 'info' });
                  this.showPopup();
                } else {
                  if (creationError.finaliseCreation === true) {
                    html = this.getHtmlMessage();
                  } else {
                    const message = creationError.message.replace(
                      '#{mail}',
                      this.email
                    );
                    html = `<p class="fs-875">${message}</p>`;
                  }
                  this.$router.push({
                    name: 'Gestion des rattachements',
                    query: {
                      creationCT: DGFIP.codeCopro,
                      html: html
                    }
                  });
                }
              });
          }
        }
      });
    },

    prepateEtatCivil() {
      let etatCivil = getEtatCivil();

      etatCivil.adresseLigne1 = this.compte.adresse;
      etatCivil.adresseLigne2 = this.compte.complement1;
      etatCivil.adresseLigne3 = this.compte.complement2;
      etatCivil.codePostal = this.compte.codePostal;
      etatCivil.ville = this.compte.ville;
      etatCivil.pays = this.compte.pays.nom;

      return etatCivil;
    },

    async checkFields() {
      let control = true;
      this.compte.adresseRequiredError = false;
      this.compte.codePostalRequiredError = false;
      this.compte.villeRequiredError = false;
      this.compte.captchaRequiredError = false;
      this.compte.captchaInvalidError = false;

      if (!this.compte.adresse.trim()) {
        this.compte.adresseRequiredError = true;
        control = false;
      }
      if (!this.compte.codePostal.trim()) {
        this.compte.codePostalRequiredError = true;
        control = false;
      }
      if (!this.compte.ville.trim()) {
        this.compte.villeRequiredError = true;
        control = false;
      }

      if (this.captchaReponse && this.captchaReponse.trim()) {
        await this.validateCaptcha();
        this.compte.captchaInvalidError = !this.captchaValidation;
        control = this.captchaValidation;
        if (!this.captchaValidation) {
          this.getCaptchaImage();
        }
      } else {
        this.compte.captchaRequiredError = true;
        control = false;
      }

      return control;
    },

    checkCaractereNonImprimable() {
      this.compte.adresse = cleanText(this.compte.adresse);
      this.compte.complement1 = cleanText(this.compte.complement1);
      this.compte.complement2 = cleanText(this.compte.complement2);
      this.compte.codePostal = cleanText(this.compte.codePostal);
      this.compte.ville = cleanText(this.compte.ville);
    },

    goToRattachement() {
      let html =
        "<p class='fs-875 text-center'>Confirmez-vous l’abandon de votre formulaire de création de compte <b>impots.gouv.fr</b> ? </p>";
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.$router.push({ name: 'Gestion des rattachements' });
        }
      });
    },
    getHtmlMessage() {
      return `<p class="fs-875">Votre compte <b>impots.gouv.fr</b> est bien créé et rattaché.<br> Merci d'activer vos habilitations via ce lien <a href=${process.env.VUE_APP_URL_DGFIP_CREATION_COMPTE}><b>${process.env.VUE_APP_URL_DGFIP_CREATION_COMPTE}</b><a><br> Ainsi, vous pourrez bénéficier de vos services fiscaux depuis <b>Portailpro.gouv</b>.</p>`;
    },

    getCaptchaImage() {
      this.$captchaService
        .getCaptchaImage()
        .then((result) => {
          this.captcha = result.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    playCaptchaAudio() {
      this.$captchaService.playCaptchaAudio(this.captcha.captchaId);
    },

    async validateCaptcha() {
      await this.$captchaService
        .validateCaptcha(this.captcha.captchaId, this.captchaReponse)
        .then((result) => {
          this.captchaValidation = result.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    ...mapActions(['showPopup', 'setContent'])
  }
};
</script>

<style scoped lang="scss">
.soulignement {
  text-decoration: underline !important;
  text-underline-offset: 4px;
}

.soulignement:hover {
  text-decoration: underline 1.5px !important;
}

.ri-arrow-left-line {
  margin: 0px !important;
}
</style>

<style lang="scss">
.span-btn {
  font-family: 'Marianne';
  font-size: 1.1rem;
  text-align: left;
  padding: 10px 0;
  z-index: 1;
}

.span-label {
  height: 21px;
  font-size: 0.9rem;
}

.pr-btn-valider {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  height: 50px;
  margin-top: 1rem;
  width: 9.1rem;
}

.pr-btn-valider:hover {
  background-color: #1212ff !important;
}

.pr-btn-annuler {
  background: white;
  color: $base-color;
  border: 2px solid $base-color !important;
  font-size: 14px;
  border: 0;
  height: 50px;
  margin-top: 1rem;
  width: 9.1rem;
  //margin-right: 1rem !important;
}

.fr-label {
  height: 21px;
  font-size: 0.9rem;
  display: block;
  padding: 0;
}

.fr-input {
  box-shadow: inset 0 -2px 0 0 #000091;
}

.champs-obligatoire {
  font-size: 13px;
  color: var(--g600);
}

.ri-error-warning-line {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}

.confirm-creation {
  margin: 2rem;
  text-align: center;
}

.pr-btn-valider:focus {
  outline: unset;
  box-shadow: 0 0 0 2px #2a7ffe;
}

.pr-btn-annuler:focus {
  outline: unset;
  box-shadow: 0 0 0 2px #2a7ffe;
}

.captcha-btn {
  cursor: pointer;
}

.fr-label::before {
  display: none;
}

.fr-link {
  cursor: pointer !important;
}

@media only screen and (max-width: 760px) {
  .complement2-mobile {
    margin-top: 1.5rem !important;
  }

  .ville-mobile {
    margin-top: 1.5rem !important;
  }

  .w-75 {
    width: 95% !important;
  }

  .fr-input {
    width: 95%;
  }

  .pr-btn-annuler {
    margin-right: 1rem !important;
  }

  .complement2-mobile {
    margin-top: 1.5rem !important;
  }

  .captcha-image {
    width: 14rem;
  }
}
</style>
